<template>
  <div class="container-fluid">
    <div class="row justify-content-center py-3">
      <div class="col-10 col-sm-8 col-md-4">
        <div class="form-group">
          <select name="seasons" id="seasons" class="form-control" @change="changeSeason">
            <option value="">{{ $t('selectOption') }}</option>
            <option v-for="(season, ind) in seasons" :key="ind" :value="season.season_id">{{ `${season.tournament_name} | ${season.division_name} | ${season.season_name}` }}</option>
          </select>
        </div>
      </div>
      <div class="col-12">
        <next-matchs :lastMatches="matches" :show-details="true" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import NextMatchs from '../../partials/NextMatchs'

export default {
  components: {
    NextMatchs
  },
  data () {
    return {
      matches: [],
      seasons: [],
      slug: '',
      lang: '',
      console: ''
    }
  },
  computed: {
    ...mapGetters([
      'teamFront'
    ])
  },
  mounted() {
    this.$store.dispatch('SET_MENU_PRIMARY_TEAM', 'fixture')
    this.slug = this.$route.params.teamSlug
    this.lang = this.$route.params.lang
    this.console = this.$route.params.console
    this.loadSeasons()
  },
  methods: {
    changeSeason(event) {
      const season = event.target.value
      if (season) {
        const obj = this.seasons.filter(s => {
          return s.season_id === season
        })
        if (obj.length > 0) {
          const params = obj.reduce(season => {
            return season
          })
          this.$router.push({ name: 'TeamFixture', params: { lang: this.lang, console: this.console, teamSlug: this.slug }, query: { tournament: params.tournament_id, division: params.division_id, season: params.season_id } });
          this.fetchMatches()
        }
      }
    },
    loadSeasons() {
      const path = `${this.lang}/console/${this.console}/team/${this.slug}/seasons`
      this.$axios.get(path).then((response) => {
        this.seasons = response.data.data
      })
    },
    fetchMatches() {
      const route = this.$route.query
      const params = {
        tournament: route.tournament,
        division: route.division,
        season: route.season
      }
      const path = `${this.lang}/console/${this.console}/team/${this.slug}/fixture`
      this.$axios.get(path, { params }).then((response) => {
        this.matches = response.data.data
      })
    }
  }
}
</script>
